@import "react-alice-carousel/lib/alice-carousel.css";
@import "react-image-lightbox/style.css";

.alice-carousel {
	margin: 16px 0;
}

.alice-carousel__dots {
	margin: 8px;
}
.alice-carousel__stage-item {
	padding: 0 8px;
}

.alice-carousel__dots-item:not(.__custom).__active {
	background-color: #006666;
}

@font-face {
	font-family: "Gilroy-ExtraBold";
	src: url("https://api.goxplore.bg/fonts/Gilroy-ExtraBold.ttf")
		format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Gilroy-Light";
	src: url("https://api.goxplore.bg/fonts/Gilroy-Light.ttf") format("truetype");
	font-weight: lighter;
}

@font-face {
	font-family: "Noto-Sans";
	src: url("https://api.goxplore.bg/fonts/NotoSans-Regular.ttf")
		format("truetype");
}

@font-face {
	font-family: "Noto-Sans-Bold";
	src: url("https://api.goxplore.bg/fonts/NotoSans-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Mixa";
	src: url("https://api.goxplore.bg/fonts/Mixa-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Mixa-Bold";
	src: url("https://api.goxplore.bg/fonts/Mixa-Bold.ttf") format("truetype");
	font-weight: bold;
}

html {
	height: 100%;
}

body {
	font-family: "Noto-Sans";
	height: 100%;
}

#root {
	height: 100%;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
}

.pac-container {
	min-width: 285px;
}

.pac-item {
	font-family: "Noto-Sans-Bold";
}
